import React from "react";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { getEvents, signInUser } from "../controllers";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Example() {
  const navigate = useNavigate();

  const [events, setEvents] = React.useState([]);
  const [event, setEvent] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [err, setErr] = React.useState(null);
  const [isSubmiting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    getEvents()
      .then((res) => {
        if (res.status === 200) {
          setEvents(res.data);
          setEvent(res.data[0]?._id);
        }
      })
      .catch((error) => console.log({ error }));
  }, []);

  const handleSignIn = async () => {
    try {
      if (!phone || !event) {
        return setErr("Fill all fields");
      }
      setIsSubmitting(true);
      const body = {
        phone,
        eventId: event,
      };
      const response = await signInUser(body);
      setIsSubmitting(false);
      if (response.status === 200) {
        return navigate("/profile", { state: response.data });
      } else {
        setErr(String(response.data.message));
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={require("../assets/imgs/pcm.png")}
              alt="PCM"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              if you haven't registered{" "}
              <a
                href="/register"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                register here
              </a>
            </p>
          </div>
          {err ? (
            <p className="mt-1 text-center text-sm text-red-600">{err}</p>
          ) : null}
          <div className="mt-8 space-y-6">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Event Name
                </label>
                <select
                  id="event"
                  name="event"
                  onChange={(e) => setPhone(e.target.value)}
                  autoComplete="event-name"
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                  {events.map((one, i) => (
                    <option value={one._id} key={i}>
                      {one.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="Phone" className="sr-only">
                  Phone Number
                </label>
                <input
                  id="Phone"
                  name="Phone"
                  type="tel"
                  onChange={(e) => setPhone(e.target.value)}
                  autoComplete="current-Phone"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Phone Number"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a className="font-medium text-indigo-600 hover:text-indigo-500">
                  Need a help?
                </a>
              </div>
            </div>

            <div>
              {isSubmiting ? (
                <div className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  <LoadingSpinner />
                </div>
              ) : (
                <button
                  onClick={handleSignIn}
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  Sign in
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
