import React, { Fragment, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { PhoneArrowDownLeftIcon } from "@heroicons/react/24/outline";
import {
  createUSSD,
  getMemberById,
  getEvents,
  getBranches,
  updateMember,
} from "../controllers";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LoadingSpinner from "../components/LoadingSpinner";
import { TreeSelect } from "primereact/treeselect";

export default function Profile() {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [loading, setLoading] = useState(false);

  const [events, setEvents] = React.useState([]);
  const [allBranches, setAllBranches] = React.useState([]);
  const [branches, setBranches] = React.useState([]);
  const [zone, setZone] = React.useState(null);
  const [isOther, setIsOther] = React.useState(false);
  const [selectedNodeKey, setSelectedNodeKey] = React.useState(-4);

  const [firstN, setFirstN] = React.useState(null);
  const [lastN, setLastN] = React.useState(null);
  const [profession, setProfession] = React.useState(null);
  const [event, setEvent] = React.useState(null);
  const [branch, setBranch] = React.useState(null);
  const [course, setCourse] = React.useState(null);
  const [gender, setGender] = React.useState(null);
  const [phone1, setPhone1] = React.useState(null);
  const [pass, setPass] = React.useState(false);
  const [isSubmiting, setIsSubmitting] = React.useState(false);

  const navigate = useNavigate();
  const [words, setWords] = useState(
    "Select your network, then fill in phone number of the chosen network then fill amount to pay."
  );
  const [phone, setPhone] = React.useState(null);
  const [state, setState] = React.useState(useLocation().state);
  const [net, setNet] = React.useState("Mpesa");
  const [hasFinish, setFinish] = React.useState(false);
  const [isStarting, setStarting] = React.useState(false);
  const [amount, setamount] = React.useState(
    state.event.reg_fee - state.amount_payable
  );
  const [err, setErr] = React.useState(null);

  const cancelButtonRef = useRef(null);
  const cancelButtonRef1 = useRef(null);

  React.useEffect(() => {
    setIsOther(false);
    if (state === null) {
      return navigate("/");
    }
    getMemberById(state._id)
      .then((res) => {
        if (res.status === 200) {
          setState(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getUser = async () => {
    try {
      setFinish(false);
      setStarting(false);
      const res = await getMemberById(state._id);
      if (res.status === 200) {
        setState(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getBranches()
      .then((res) => {
        if (res.status === 200) {
          setAllBranches(res.data);
        }
      })
      .catch((err) => console.log({ err }));
    getEvents()
      .then((res) => {
        if (res.status === 200) {
          setEvents(res.data);
          setEvent(res.data[0]?._id);
        }
      })
      .catch((error) => console.log({ error }));
  }, []);

  React.useEffect(() => {
    const list = [];
    allBranches.forEach((one) => {
      if (one.zone?.toUpperCase() === zone?.toUpperCase()) {
        list.push(one);
      }
    });
    setBranch(list[0]?._id);
    return setBranches(list);
  }, [zone]);

  const formartCurrency = (amount) => {
    return amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const nets = [
    { id: "Mpesa", name: "M-Pesa" },
    { id: "TigoPesa", name: "Tigo Pesa" },
    { id: "AirtelMoney", name: "Airtel Money" },
  ];

  function generateThreeRandomString() {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 3; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const submitPay = async () => {
    try {
      if (!phone || !net) {
        return setErr("Fill all fields");
      }
      let firstThree = phone.substring(0, 3);
      if (
        firstThree !== "065" &&
        firstThree !== "067" &&
        firstThree !== "068" &&
        firstThree !== "069" &&
        firstThree !== "071" &&
        firstThree !== "074" &&
        firstThree !== "075" &&
        firstThree !== "076" &&
        firstThree !== "078"
      ) {
        return setErr("Invalid Phone Number");
      }
      let isnum = /^\d+$/.test(phone);
      if (!isnum) {
        return setErr("Invalid Phone Number");
      }
      let isnumAm = /^\d+$/.test(amount);
      if (!isnumAm) {
        return setErr("Amount shoud be numbers");
      }
      if (Number(amount) < 1000) {
        return setErr("Amount should be greater than 1,000Tsh");
      }
      setStarting(true);
      const body = {
        appName: "TUCASA",
        network: net,
        amount: Number(amount),
        productName: state.event.name,
        phone: Number(phone),
        refID: generateThreeRandomString() + `${state.member_no}`,
      };
      const response = await createUSSD(body);
      if (response.data.response_code === 200) {
        setWords("Check your phone to finish the payments.");
        setFinish(true);
      } else {
        setStarting(false);
        setFinish(false);
        setErr(
          "failed to initialize payments, Please make sure you have strong internet connection, Try again"
        );
      }
    } catch (error) {
      setStarting(false);
      setFinish(false);
      console.log(error);
    }
  };

  const upateTheUser = async () => {
    try {
      setIsSubmitting(true);
      const body = {
        first_name: firstN ? firstN : state.first_name,
        last_name: lastN ? lastN : state.last_name,
        phone: phone1 ? phone1 : state.phone,
        school: course ? course : state.school,
        union: zone ? zone : state.union,
        gender: gender ? gender : state.gender,
        profession: profession ? profession : state.profession,
        //branch: branch ? branch : state.branch._id,
      };

      const response = await updateMember(state._id, body);
      setIsSubmitting(false);
      if (response.status === 202) {
        return window.location.reload();
      } else {
        return Alert(response.data.message);
      }
    } catch (error) {
      console.log("failed to update user", error);
    }
  };

  const handleImageUpload = (fil) => {
    setLoading(true);
    const data = new FormData();
    data.append("file", fil);
    data.append("upload_preset", "tucasa");
    fetch("https://api.cloudinary.com/v1_1/iass/image/upload", {
      method: "POST",
      body: data,
    })
      .then((resp) => resp.json())
      .then(async (res) => {
        const response = await updateMember(state._id, {
          profile_pic: res.secure_url,
        });
        setLoading(false);
        if (response.status === 202) {
          return window.location.reload();
        } else {
          return Alert(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const zones = [
    "Burundi",
    "East Congo",
    "North East Congo",
    "East Kenya",
    "West Kenya",
    "Northern Tanzania",
    "Southern Tanzania",
    "Rwanda",
    "Uganda",
    "South Sudan",
    "West Ethiopia",
    "Other",
  ];

  const nodes = [
    {
      label: "PASTOR",
      data: "Pastor",
      children: [
        {
          key: "Division Director",
          label: "Division Director",
          data: "Division Director",
        },
        {
          key: "Union Director",
          label: "Union Director",
          data: "Union Director",
        },
        {
          key: "Conference/Field Director",
          label: "Conference/Field Director",
          data: "Conference/Field Director",
        },
        {
          key: "District Pastor",
          label: "District Pastor",
          data: "District Pastor",
        },
        {
          key: "Chaplain",
          label: "Chaplain",
          data: "Chaplain",
        },
      ],
    },
    {
      label: "PROFESSIONAL",
      data: "PROFESSIONAL",
      children: [
        {
          key: "Medical Doctor",
          label: "Medical Doctor",
          data: "Medical Doctor",
        },
        {
          key: "Communication",
          label: "Communication",
          data: "Communication",
        },
        {
          key: "Guest Speaker",
          label: "Guest Speaker",
          data: "Guest Speaker",
        },
        {
          key: "Presenter",
          label: "Presenter",
          data: "Presenter",
        },
        {
          key: "Other",
          label: "Other",
          data: "Other",
        },
      ],
    },
    {
      label: "STUDENT",
      data: "STUDENT",
      children: [
        {
          key: "University Student",
          label: "University Student",
          data: "University Student",
        },
        {
          key: "College Student",
          label: "College Student",
          data: "College Student",
        },
        {
          key: "Institute Student",
          label: "Institute Student",
          data: "Institute Student",
        },
      ],
    },
  ];

  const signUnion = (e) => {
    setZone(e.target.value);
    return setIsOther(false);
  };

  if (state !== null) {
    return (
      <>
        <div className="bg-white shadow sm:rounded-lg sm:my-5 lg:my-16">
          <div className="flex justify-between">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Member's Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Personal details and registration.
              </p>
            </div>
            <button
              type="button"
              onClick={() => setOpen1(true)}
              className="rounded  px-3 py-1 text-blue-400 hover:text-blue-600 focus:outline-none "
            >
              Edit Profile
            </button>
          </div>

          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Profile Photo
                </dt>
                <div>
                  <div className="mb-5 cursor-pointer h-24 w-24 bg-blue-100 rounded-2xl">
                    <img
                      src={
                        state.profile_pic
                          ? state.profile_pic
                          : require("../assets/imgs/user.png")
                      }
                      width="100%"
                      height="100%"
                    />
                  </div>
                  {loading ? (
                    <div className="px-6 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <input
                      type="file"
                      onChange={(e) => handleImageUpload(e.target.files[0])}
                    />
                  )}
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Full name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {state.first_name} {state.last_name}
                </dd>
              </div>
              <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Member's ID
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {state.member_no}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  School Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {state.school}
                </dd>
              </div>
              <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Registered for
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {state.event.name}
                </dd>
              </div>

              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Member's Union
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {state.union}
                </dd>
              </div>
              <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Phone Number
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {state.phone}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Registration Fee
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {formartCurrency(state.event.reg_fee)}{" "}
                  {state.event?.currency?.toUpperCase()}
                </dd>
              </div>
              <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Amount Paid
                </dt>
                <div className="flex items-center">
                  <dd className="mr-5 mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {formartCurrency(state.amount_payable)}{" "}
                    {state.event?.currency?.toUpperCase()}
                  </dd>
                  {/* {state.amount_payable < state.event.reg_fee ? (
                    <button
                      type="button"
                      onClick={() => setOpen(true)}
                      className="rounded bg-gray-800 px-3 py-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      Pay Now
                    </button>
                  ) : null} */}
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Gender</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {state.gender ? state.gender?.toUpperCase() : ""}
                </dd>
              </div>
              <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Profession
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {state.profession}
                </dd>
              </div>

              {/* <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Attachments</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul
                  role="list"
                  className="divide-y divide-gray-200 rounded-md border border-gray-200"
                >
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        my_id_card.pdf
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a className="font-medium text-indigo-600 hover:text-indigo-500">
                        Download
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div> */}
            </dl>
          </div>
        </div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={() => setOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                          <PhoneArrowDownLeftIcon
                            className="h-6 w-6 text-blue-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Payment Dialog
                          </Dialog.Title>
                          {err ? (
                            <div className="mt-2">
                              <p className="text-sm text-red-600">{err}</p>
                            </div>
                          ) : null}
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">{words}</p>
                          </div>
                          <div className="col-span-6 mt-5">
                            <label
                              htmlFor="network"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Network
                            </label>
                            <select
                              id="network"
                              name="network"
                              onChange={(e) => {
                                setErr(null);
                                setNet(e.target.value);
                              }}
                              autoComplete="network-name"
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                              {nets.map((one, i) => (
                                <option value={one.id} key={i}>
                                  {one.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-span-6 mt-3">
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Phone Number
                            </label>
                            <input
                              type="tel"
                              name="phone"
                              id="phone"
                              maxLength={10}
                              onChange={(e) => {
                                setErr(null);
                                setPhone(e.target.value);
                              }}
                              placeholder="0780100100"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-6 mt-3">
                            <label
                              htmlFor="amount"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Amount (Tsh)
                            </label>
                            <input
                              type="number"
                              name="amount"
                              id="amount"
                              onChange={(e) => {
                                setErr(null);
                                setamount(e.target.value);
                              }}
                              placeholder={formartCurrency(
                                state.event.reg_fee - state.amount_payable
                              )}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      {isStarting ? null : (
                        <button
                          type="button"
                          onClick={submitPay}
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Pay Now
                        </button>
                      )}
                      {hasFinish ? (
                        <button
                          type="button"
                          onClick={() => {
                            getUser();
                            setOpen(false);
                          }}
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          I have completed
                        </button>
                      ) : null}
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={open1} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef1}
            onClose={() => setOpen1(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="sm:my-5 lg:my-16 ">
                    <div className="mt-5 md:col-span-2 md:mt-0">
                      <div className="overflow-hidden shadow sm:rounded-md">
                        <div className="bg-white px-4 py-5 sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="first-name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                First name
                              </label>
                              <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                defaultValue={state.first_name}
                                placeholder={state.first_name}
                                onChange={(e) => setFirstN(e.target.value)}
                                autoComplete="given-name"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Last name
                              </label>
                              <input
                                type="text"
                                onChange={(e) => setLastN(e.target.value)}
                                name="last-name"
                                id="last-name"
                                defaultValue={state.last_name}
                                placeholder={state.last_name}
                                autoComplete="family-name"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>

                            <div className="col-span-6 ">
                              <label
                                htmlFor="conference"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Union
                              </label>
                              <select
                                onChange={(e) =>
                                  e.target.value.toLowerCase() === "other" ||
                                  e.target.value.toLowerCase() === "others"
                                    ? setIsOther(true)
                                    : signUnion(e)
                                }
                                id="conference"
                                name="conference"
                                defaultValue={state.union}
                                placeholder={state.union}
                                autoComplete="conference-name"
                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              >
                                {zones.map((one, i) => (
                                  <option key={i}>{one}</option>
                                ))}
                              </select>
                              {isOther ? (
                                <input
                                  type="text"
                                  name="union"
                                  id="union"
                                  placeholder="Type your union"
                                  onChange={(e) => setZone(e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              ) : null}
                            </div>

                            <div className="col-span-6">
                              <label
                                htmlFor="course"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Institute/College/University
                              </label>
                              <input
                                type="text"
                                name="course"
                                id="course"
                                defaultValue={state.school}
                                placeholder={state.school}
                                onChange={(e) => setCourse(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="phone"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Phone Number
                              </label>
                              <input
                                type="tel"
                                name="phone"
                                id="phone"
                                defaultValue={state.phone}
                                placeholder={state.phone}
                                onChange={(e) => setPhone1(e.target.value)}
                                autoComplete="address-level2"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="branch"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Gender
                              </label>
                              <select
                                id="branch"
                                name="branch"
                                onChange={(e) => setGender(e.target.value)}
                                autoComplete="branch-name"
                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                            <div className="col-span-6">
                              <label
                                htmlFor="branchee"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Profession*
                              </label>
                              <TreeSelect
                                className="mt-1 w-full rounded-md border border-gray-300 bg-white  px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm md:w-20rem"
                                value={selectedNodeKey}
                                onChange={(e) => {
                                  if (e.value !== "Other") {
                                    setProfession(e.value);
                                  }
                                  setSelectedNodeKey(e.value);
                                }}
                                options={nodes}
                                placeholder="Select your profession"
                                showClear
                              ></TreeSelect>
                            </div>
                            {selectedNodeKey === "Other" ? (
                              <div className="col-span-6">
                                <input
                                  type="text"
                                  name="proffession"
                                  id="profession"
                                  placeholder="Write your profession*"
                                  onChange={(e) =>
                                    setProfession(e.target.value)
                                  }
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                          {isSubmiting ? (
                            <div className="px-6 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                              <LoadingSpinner />
                            </div>
                          ) : (
                            <div>
                              <button
                                onClick={() => setOpen1(false)}
                                className="mr-2 inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={upateTheUser}
                                className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                              >
                                Update Info
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}
