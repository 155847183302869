import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center p-3 bg-white-50">
      <div>
        <a
          style={{ opacity: 0.2 }}
          href="https://tucasa-super.web.app"
          className="text-sm font-normal cursor-pointer hover:font-medium mx-7 "
        >
          Admin
        </a>
      </div>
      <div className="flex items-center">
        <p className="text-sm font-light  ">ECD - Public Campus Ministry </p>
        <p className="text-sm font-light mr-7 ml-3 ">
          ©{new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
