import React from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";

import HomePage from "./screens/Homepage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Register from "./screens/Register";
import Signin from "./screens/Signin";
import Profile from "./screens/Profile";

export default function App() {
  return (
    <PrimeReactProvider>
      <Router>
        <div className="flex flex-col justify-between  h-screen lg:mx-32 md:mx-24 sm:mx-5 ">
          <Header />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </PrimeReactProvider>
  );
}
