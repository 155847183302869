import axios from "axios";

const API_URL = "https://server.msosidrop.co.tz:3100/api";

export const getEvents = async () => {
  try {
    const response = await axios.get(`${API_URL}/event`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log({ error });
  }
};

export const getBranches = async () => {
  try {
    const response = await axios.get(`${API_URL}/branch`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log({ error });
  }
};

export const createMember = async (body) => {
  try {
    const response = await axios.post(`${API_URL}/member/add`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log({ error });
  }
};

export const getMemberById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/member/${id}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log({ error });
  }
};

export const updateMember = async (id, body) => {
  try {
    const response = await axios.put(`${API_URL}/member/update/${id}`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log({ error });
  }
};

export const signInUser = async (body) => {
  try {
    const response = await axios.post(`${API_URL}/member/signin`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log({ error });
  }
};

export const signinAdmin = async (body) => {
  try {
    const response = await axios.post(`${API_URL}/admin/login`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log({ error });
  }
};

export const createUSSD = async (body) => {
  try {
    const response = await axios.post(`${API_URL}/pay/send-ussd`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log({ error });
  }
};
